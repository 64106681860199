import { Color } from "@angular-material-components/color-picker";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as tinycolor from "tinycolor2";
import { INetworkTheme, INetworkThemeInstance, IThemeColor } from "../interfaces/networkTheme.interface";

@Injectable({
    providedIn: "root"
})
export class ThemeService {
    private defaultTheme!: INetworkTheme;
    public defaultTheme$: BehaviorSubject<INetworkTheme | null> = new BehaviorSubject<INetworkTheme | null>(null);
    public appTitle!: string;

    private primaryColorPalette: IThemeColor[] = [];
    private accentColorPalette: IThemeColor[] = [];

    public themeLogoUrl$ = new BehaviorSubject<string | null>(null);

    constructor() {
        this.initBranding();
    }

    private initBranding() {
        // Dynamic branding based on URL
        const appTitleEl = document.querySelector<HTMLTitleElement>("#appTitle")!;
        const faviconEl = document.querySelector<HTMLLinkElement>("#favicon")!;
        let favicon: string;
        let defaultTheme: INetworkTheme;
        switch (window.location.hostname) {
            // case "localhost": // Dev override
            case "dev.portal.processretailgroup.com":
            case "test.portal.processretailgroup.com":
            case "preprod.portal.processretailgroup.com":
            case "portal.processretailgroup.com":
                this.appTitle = "Signage Portal";
                favicon = "assets/icons/favicon-prg.ico";
                defaultTheme = {
                    primaryColor: new Color(172, 31, 45),
                    accentColor: new Color(208, 208, 208),
                    logoUrl: "assets/logos/logo-prg.png",
                    networkId: null
                };
                break;
            default:
                this.appTitle = appTitleEl.innerText;
                favicon = faviconEl.href;
                defaultTheme = {
                    primaryColor: new Color(46, 104, 139),
                    accentColor: new Color(148, 169, 182),
                    logoUrl: null,
                    networkId: null
                };
        }

        appTitleEl.innerText = this.appTitle;
        faviconEl.href = favicon;

        this.setDefaultTheme(defaultTheme);
    }

    public saveTheme(theme?: INetworkTheme | INetworkThemeInstance) {
        const {
            primaryColor = this.defaultTheme.primaryColor!,
            accentColor = this.defaultTheme.accentColor!,
            logoUrl = null
        } = theme || this.defaultTheme;

        this.primaryColorPalette = this.computeColors(primaryColor || this.defaultTheme.primaryColor);
        this.accentColorPalette = this.computeColors(accentColor || this.defaultTheme.accentColor);
        this.updateTheme(this.primaryColorPalette, "primary");
        this.updateTheme(this.accentColorPalette, "accent");
        this.themeLogoUrl$.next(logoUrl);
    }

    private updateTheme(palette: IThemeColor[], theme: string) {
        palette.forEach((color: IThemeColor) => {
            document.documentElement.style.setProperty(`--theme-${theme}-${color.name}`, color.rgb);
            document.documentElement.style.setProperty(
                `--theme-${theme}-contrast-${color.name}`,
                color.darkContrast ? "rgba(0,0,0, 0.8)" : "white"
            );
        });
    }

    private computeColors(color: Color): IThemeColor[] {
        return [
            this.getColorObject(tinycolor(color.hex).lighten(52), "50"),
            this.getColorObject(tinycolor(color.hex).lighten(37), "100"),
            this.getColorObject(tinycolor(color.hex).lighten(26), "200"),
            this.getColorObject(tinycolor(color.hex).lighten(12), "300"),
            this.getColorObject(tinycolor(color.hex).lighten(6), "400"),
            this.getColorObject(tinycolor(color.hex), "500"),
            this.getColorObject(tinycolor(color.hex).darken(6), "600"),
            this.getColorObject(tinycolor(color.hex).darken(12), "700"),
            this.getColorObject(tinycolor(color.hex).darken(18), "800"),
            this.getColorObject(tinycolor(color.hex).darken(24), "900"),
            this.getColorObject(tinycolor(color.hex).lighten(50).saturate(30), "A100"),
            this.getColorObject(tinycolor(color.hex).lighten(30).saturate(30), "A200"),
            this.getColorObject(tinycolor(color.hex).lighten(10).saturate(15), "A400"),
            this.getColorObject(tinycolor(color.hex).lighten(5).saturate(5), "A700")
        ];
    }

    private getColorObject(color: tinycolor.Instance, name: string): IThemeColor {
        return {
            name: name,
            hex: color.toHexString(),
            rgb: color.toRgbString(),
            darkContrast: color.isLight()
        };
    }

    public setDefaultTheme(theme: INetworkTheme) {
        this.defaultTheme = theme;
        this.defaultTheme$.next(theme);
        this.saveTheme(theme);
    }
}
